<template>
  <div class="wrap-system-setting">
    <TopNav
      :title="'left_bar_system_setting'"
      :navList="navList"
      v-model="tabView"
      @goTo="switchTab"
    />
    <div class="container">
      <!-- <component :is="tabView"></component> -->
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Global from '@/components/SystemSetting/GlobalSetting.vue'
// import Web from '@/components/SystemSetting/CustomWeb.vue'
// import Backend from '@/components/SystemSetting/BackendSetting.vue'
// import Server from '@/components/SystemSetting/ServerCapability.vue'
// import AiBox from '@/components/SystemSetting/AiBoxMgr.vue'
import { routeGo, systemPermission } from '@/router'

export default {
  name: 'SystemSetting',
  components: {
    TopNav: () => import(`@/components/tools/TopNav.vue`),

    // Global,
    // Web,
    // Backend,
    // Server,
    // AiBox
  },
  data() {
    return {
      tabView: null // 'GlobalSetting',
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        const tab = this.navList.find((_t) => {
          const key = String(_t.comp).toLowerCase()
          const path = this.$route.path.split('/').pop().toLowerCase()

          return path.includes(key)
        })

        this.tabView = tab ? tab.comp : null
      }
    }
  },
  computed: {
    ...mapState(['staff', 'permissionV2']),
    navList() {
      const isStaff = this.staff === 1 ? true : false
      const {global, web, backend, server, aiBox} = systemPermission(this.permissionV2)
      let list = []

      // 全域設定
      if (global) {
        list.push({
          navTitle: 'system_global_setting',
          comp: 'GlobalSetting',
        })
      }

      // 客製網頁
      if (web) {
        list.push({
          navTitle: 'system_custom_web',
          comp: 'CustomWeb',
        })
      }

      // 後台設定
      if (backend) {
        list.push({
          navTitle: 'system_backend_setting',
          comp: 'BackendSetting',
        })
      }

      // 伺服器能力
      if (isStaff && server) {
        list.push({
          navTitle: 'system_server_capability',
          comp: 'ServerCapability',
        })
      }

      // 辨識盒管理
      if (isStaff && aiBox) {
        list.push({
          navTitle: 'system_aibox',
          comp: 'AiBoxMgr',
        })
      }

      return list
    }
  },
  methods: {
    switchTab(comp) {
      this.tabView = comp
      routeGo(`/system-setting/${comp.charAt(0).toLowerCase()}${comp.slice(1)}`)
    }
  },
}
</script>

<style lang="scss" scoped>
.wrap-system-setting {
  width: calc(100% - 50px);
  height: 100vh;
  display: grid;
  grid-template-rows: px2rem($NavH) calc(100% - px2rem($NavH));
  /* grid-template-columns: 50px calc(100% - 50px); */
  grid-template-columns: 0 100%;
  grid-template-areas:
    '. navbar'
    '. container';
}

.sidebar {
  grid-area: sidebar;
}

// .navbar {
//   grid-area: navbar;
//   display: flex;
//   align-items: center;
//   background: #151B35;
// }

// .navbar > .title {
//   font-size: 36px;
//   line-height: 54px;
//   color: #ffffff;
//   padding-left: 40px;
//   padding-right: 60px;
// }

// .navbar > .tab {
//   font-size: 24px;
//   font-weight: 300;
//   line-height: 60px;
//   color: #ffffff;
//   margin-right: 52px;
//   margin-top: 5px;
//   user-select: none;
//   border-bottom: 5px solid transparent;
//   cursor: pointer;
// }

// // .navbar > .tab:not(:last-child) {
// //   color: #777;
// //   cursor: not-allowed;
// // }

// .navbar > .select-tab {
//   color: #FFB703;
//   border-color: #FFB703;
//   transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
// }

.container {
  grid-area: container;
}
</style>
